import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ClientProvider } from './GraphQLClient';
import './App.scss';
import { Graphiql } from './views/Pages/Graphiql/Graphiql';
import { useAuth } from './auth/AuthProvider';
import { Loading } from './components/Loading/Loading';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Version from './components/Version';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const NoChrome = React.lazy(() => import('./containers/NoChrome/NoChrome'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const Builders = React.lazy(() => import('./views/BuilderApprovals/Public'));

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();

function App(props) {
  const auth = useAuth();

  return (
    <>
      <React.Suspense fallback={<Loading />}>
        {auth.token && auth.user ? (
          <BrowserRouter>
            <ClientProvider>
              {auth.me ? (
                <Switch>
                  <Route path="/builders/:slug" name="Builders" render={(props) => <Builders {...props} />} />
                  {routes.map((route, idx) => {
                    return route.layout && route.layout === 'NoChrome' ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => (
                          <NoChrome>
                            <route.component {...props} />
                          </NoChrome>
                        )}
                      />
                    ) : null;
                  })}
                  <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                  <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                  <Route path="/graphiql" name="GraphiQL" render={(props) => <Graphiql {...props} />} />
                  <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
                  <Route path="/builders/:slug" name="Builders" render={(props) => <Builders {...props} />} />
                </Switch>
              ) : (
                auth.meFailed && <LoginFailed />
              )}
            </ClientProvider>
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <Switch>
              <Route path="/builders/:slug" name="Builders" render={(props) => <Builders {...props} />} />
              <Route path="*" name="login" render={(props) => <Login {...props} />} />
            </Switch>
          </BrowserRouter>
        )}
      </React.Suspense>
      <ToastContainer position="top-center" />
    </>
  );
}

const LoginFailed = () => {
  const [loading, setLoading] = useState(true);
  const auth = useAuth();

  // We need to give the auth process enough time to retry GET_ME
  // before we show the login failed screen
  // Clear loading after 5 seconds
  useEffect(() => {
    const t = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(t);
  }, []);

  return (
    <>
      {(!!auth.loading || !!loading) && <Loading />}
      {!auth.loading && !loading && (
        <div style={{ margin: 20 }}>
          <h1>Failed to login</h1>
          <p>EMAIL: {auth.user?.email}</p>
          <p>
            VERSION: <Version />
          </p>
          <p>Your login attempt has failed, please check your credentials and try again.</p>
          <p>
            If you continue to experience this issue please contact your administrator and let them know the email
            address you are attempting to login with.
          </p>
          <p>
            <button className="btn btn-primary" onClick={() => window.location.reload()}>
              Reload page
            </button>
          </p>
          <p>
            <button className="btn btn-secondary" onClick={() => auth.signout()}>
              Signout and Try again
            </button>
          </p>
        </div>
      )}
    </>
  );
};

export default App;
