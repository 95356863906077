import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: '', errorInfo: '', hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // What can we do with this error?
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }

  render() {
    const { hasError, errorInfo, error } = this.state;

    if (hasError) {
      return (
        <div className="card my-5">
          <div className="card-header">
            <p>
              There was an error in loading this page.{' '}
              <span
                style={{ cursor: 'pointer', color: '#0077FF' }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload this page
              </span>{' '}
            </p>
            {errorInfo && (
              <p>
                <a
                  href={`mailto:melissa.ferreri@adtechcontracting.com.au?subject=LeQuinn error message&body=${encodeURIComponent(
                    'An error occurred in LeQuinn:\r\n' + error?.message + '\r\n' + errorInfo.componentStack.toString()
                  )}`}
                >
                  Email the error
                </a>
              </p>
            )}
          </div>
          <div className="card-body">
            <div>
              {errorInfo && (
                <pre>
                  {error?.message}
                  {errorInfo.componentStack.toString()}
                </pre>
              )}
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
