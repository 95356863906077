import { setGlobal, resetGlobal } from 'reactn';
// import { getUser } from "security";

export const initialiseState = () => {
  // console.log('initialiseState();');

  resetGlobal();

  // const initialUser = getUser();

  // Set initial state
  setGlobal({
    //        user: { isAuthenticated: initialUser !== null, ...initialUser },
    hubConnection: [],
    subscriptions: []
  });
};

export default initialiseState;
