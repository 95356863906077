import React, { useState } from 'react';

import { Container, Col, Row, Table, Button, Badge } from 'reactstrap';
import { useAuth } from '../../auth/AuthProvider';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import moment from 'moment';
import 'moment-timezone';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';

export const FileUpload = ({ onFileUploaded, onFileFailed, ...props }) => {
  const taskId = props.taskId ? parseInt(props.taskId) : null;
  const projectId = props.projectId ? parseInt(props.projectId) : null;
  const constructionId = props.constructionId ? parseInt(props.constructionId) : null;
  const groupAccommodationId = props.groupAccommodationId ? parseInt(props.groupAccommodationId) : null;
  const tags = props.tags ? props.tags : [];
  const auth = useAuth();
  const isDocAttachment = props.isDocAttachment ? true : false;

  let filePaths = [];

  const getPresignedUploadParams = (fullPath, size) => {
    return fetch('/api/attachments/getPresignedUploadParams', {
      method: 'POST',
      body: JSON.stringify({
        name: fullPath,
        sizeBytes: size,
        taskId: isNaN(taskId) ? undefined : taskId,
        tags: tags,
        projectId: isNaN(projectId) ? undefined : projectId,
        constructionId: isNaN(constructionId) ? undefined : constructionId,
        groupAccommodationId: isNaN(groupAccommodationId) ? undefined : groupAccommodationId,
        isDocAttachment: isDocAttachment
      }),
      headers: { 'Content-Type': 'application/json', authorization: `Bearer ${auth.token}` }
    }).then((res) => res.json());
  };

  const removeFailedAttachment = (id) => {
    return fetch('/api/attachments/' + id, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', authorization: `Bearer ${auth.token}` }
    }).then((res) => res.json());
  };

  const getUploadParams = async ({ file, meta }) => {
    //const {uploadUrl, fileUrl} = await getPresignedUploadParams();
    //return { body: file, meta: { fileUrl }, url: uploadUrl }
    const filePath = filePaths.filter((x) => x.endsWith(meta.name))[0];
    const path = file.webkitRelativePath ? '/' + file.webkitRelativePath : filePath;

    const { attachment, uploadUrl } = await getPresignedUploadParams(path, meta.size);
    filePaths = filePaths.filter((x) => x !== filePath);
    return { body: file, meta: { attachment }, url: uploadUrl, method: 'PUT' };
  };

  const handleChangeStatus = ({ meta, remove }, status) => {
    //console.log({status, meta});
    if (status === 'headers_received') {
      //toast(`${meta.name} uploaded!`)
      if (onFileUploaded) {
        onFileUploaded(meta);
      }
      remove();
    } else if (status === 'aborted' || status === 'exception_upload') {
      //toast(`${meta.name}, upload failed...`)
      if (onFileFailed) {
        onFileFailed(meta);
      }
      removeFailedAttachment(meta.attachment.id);
    }
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        chosenFiles.forEach((x) => {
          filePaths.push(x.fullPath);
        });
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      maxSizeBytes={1024 * 1024 * 1000} // 1000MB
      multiple={true}
      autoUpload={true}
      getFilesFromEvent={getFilesFromEvent}
      InputComponent={Input}
    />
  );
};

function GetFilename(url) {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1) {
      return m[1];
    }
  }
  return '';
}

const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
  const folderText = files.length > 0 ? 'Add more folders' : 'Select a folder';
  const fileText = files.length > 0 ? 'Add more files' : 'Select files';

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col className="text-center pt-1">
          <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
            {fileText}
            <input
              style={{ display: 'none' }}
              type="file"
              accept={accept}
              multiple
              onChange={(e) => {
                getFilesFromEvent(e).then((chosenFiles) => {
                  onFiles(chosenFiles);
                });
              }}
            />
          </label>
        </Col>
        <Col className="text-center pt-1">
          <label style={{ backgroundColor: '#007bff', color: '#fff', cursor: 'pointer', padding: 15, borderRadius: 3 }}>
            {folderText}
            <input
              style={{ display: 'none' }}
              type="file"
              accept={accept}
              multiple
              directory=""
              webkitdirectory=""
              onChange={(e) => {
                getFilesFromEvent(e).then((chosenFiles) => {
                  onFiles(chosenFiles);
                });
              }}
            />
          </label>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col className="text-center pb-2" style={{ fontSize: '26px', fontWeight: '600', color: '#2484FF' }}>
          Drop Files
        </Col>
      </Row>
    </Container>
  );
};

export const FileAttachments = ({
  projectId,
  taskId,
  attachments,
  onFileUploaded,
  onFileRemoved,
  allowDelete,
  ...props
}) => {
  const auth = useAuth();

  const removeAttachment = (id) => {
    return fetch('/api/attachments/' + id, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', authorization: `Bearer ${auth.token}` }
    }).then((res) => res.json());
  };

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  return (
    <>
      <FileUpload onFileUploaded={onFileUploaded} projectId={projectId} taskId={taskId} />
      {attachments && attachments.length ? (
        <>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Title</th>
                <th>Tags</th>
                <th>Size</th>
                <th>Uploaded</th>
                <th>Sync</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {attachments
                .map((a) => (
                  <tr key={a.id}>
                    <td>
                      <a target="_blank" href={a.url}>
                        {a.title || GetFilename(a.url)}
                      </a>
                    </td>
                    <td>{a.tags ? <Badge>{a.tags}</Badge> : null}</td>
                    <td>{humanFileSize(a.sizeBytes)}</td>
                    <td>{moment.utc(a.createdUtc).tz(moment.tz.guess()).format('DD MMM YYYY hh:mm A')}</td>
                    <td>{a.syncStatus ? <Badge>{a.syncStatus}</Badge> : null}</td>
                    <td>
                      {allowDelete && a.syncStatus !== 'synced' ? (
                        <Button
                          close
                          onClick={async () => {
                            await removeAttachment(a.id);
                            if (onFileRemoved) {
                              onFileRemoved(a);
                            }
                          }}
                        >
                          <i className="icon-close"></i>
                        </Button>
                      ) : null}
                    </td>
                  </tr>
                ))
                .reverse()}
            </tbody>
          </Table>
        </>
      ) : (
        'No attachments'
      )}
    </>
  );
};
