// db.js
import Dexie from 'dexie';

export const db = new Dexie('lequinnDatabase');

db.version(3).stores({
  photos: '++id, name, description, blob, url, tags, constructionId, size, status', // Primary key and indexed props
  photoHistory:
    'id, name, constructionId, attachmentId, attachmentUrl, size, isCleanedUp, getUrl, redoGetUrl, uploadAttachment, uploadAttachmentError, getUploadParams, getUploadParamsError, createdDate, lastUpdated'
});

db.version(2).stores({
  photos: '++id, name, description, blob, url, tags, constructionId, size, status', // Primary key and indexed props
  photoHistory:
    'id, name, constructionId, size, isCleanedUp, getUrl, redoGetUrl, uploadAttachment, uploadAttachmentError, getUploadParams, getUploadParamsError, createdDate, lastUpdated'
});

db.version(1).stores({
  photos: '++id, name, description, blob, url, tags, constructionId, size, status' // Primary key and indexed props
});
