import React from 'react';
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import QRCode from 'qrcode.react';

const DraftBuilderApprovalMessage = ({ selectedJobsData, requestSent, handleSelectJob, handleCloseApproval }) => {
  const hasHandleSelectJob = !!handleSelectJob;

  if (hasHandleSelectJob && selectedJobsData?.jobs?.length === 1 && !selectedJobsData?.jobs[0]?.selected) {
    handleSelectJob(0);
  }

  return (
    <Modal size="lg" isOpen={!!selectedJobsData} backdrop="static">
      <ModalHeader>Select jobs for approval</ModalHeader>
      <ModalBody>
        <h4>{selectedJobsData.addressText}</h4>
        <Table responsive>
          <thead>
            <tr>
              <th>Address</th>
              <th>Work Order</th>
            </tr>
          </thead>
          <tbody>
            {selectedJobsData.jobs.map((job, index) => (
              <tr
                key={index}
                style={{
                  cursor: hasHandleSelectJob ? 'pointer' : 'default',
                  backgroundColor: job.selected || !hasHandleSelectJob ? '#FCF3CF' : ''
                }}
                onClick={hasHandleSelectJob ? () => handleSelectJob(index) : undefined}
              >
                <td>{job.address}</td>
                <td>{job.workOrder}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooterWithQR
        selectedJobsData={selectedJobsData}
        requestSent={requestSent}
        handleCloseApproval={handleCloseApproval}
      />
    </Modal>
  );
};

const ModalFooterWithQR = ({ selectedJobsData, requestSent, handleCloseApproval }) => {
  const smsURI = `smsto:${selectedJobsData.phoneNumber}?body=${encodeURIComponent(selectedJobsData.message)}`;

  return (
    <ModalFooter>
      <div className="d-flex flex-column w-100">
        {selectedJobsData.jobs.filter((job) => job.selected).length > 0 && (
          <>
            {/* Desktop */}
            <div style={{ display: window.innerWidth > 900 ? 'block' : 'none' }}>
              <div className="mb-2">
                Scan the QR code below with your phone to copy an approval request message ready to send to{' '}
                {selectedJobsData.siteContact}.
              </div>

              <QRCode value={smsURI} size={512} level={'H'} includeMargin={true} />
            </div>
            {/* Mobile */}
            <div style={{ display: window.innerWidth <= 900 ? 'block' : 'none' }}>
              <div style={{ marginBottom: '10px' }}>
                <a href={smsURI}>Draft SMS</a>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 mt-3 align-items-center flex-wrap">
              <div className="mb-2">
                <Button color="danger" className="text-nowrap" onClick={() => handleCloseApproval()}>
                  I did not send the message
                </Button>
              </div>
              <div className="mb-2">
                <Button color="success" className="text-nowrap" onClick={requestSent}>
                  I sent the message
                </Button>
              </div>
            </div>
          </>
        )}
        {selectedJobsData.jobs.filter((job) => job.selected).length == 0 && (
          <Button onClick={() => handleCloseApproval()}>Cancel</Button>
        )}
      </div>
    </ModalFooter>
  );
};

export default DraftBuilderApprovalMessage;
