import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useMutation } from '@apollo/react-hooks';

const UPDATE_TASKCOMMENTS = gql`
  mutation UpdateConstructionTaskComments($id: Int!, $comments: String) {
    updateConstructionTaskComments(id: $id, comments: $comments) {
      id
      comments
    }
  }
`;

const TaskQuickEdit = (props) => {
  const { onTaskEdited } = props;
  const [updateTaskComments, {}] = useMutation(UPDATE_TASKCOMMENTS);

  const [constructionTask, setConstructionTask] = useState(null);

  useEffect(() => {
    if (props.constructionTask !== null) {
      setConstructionTask(props.constructionTask);
    }
  }, [props.constructionTask]);

  const handleChange = (e) => {
    if (e.target.value === '') {
      constructionTask[e.target.name] = undefined;
    } else {
      constructionTask[e.target.name] = e.target.value;
    }

    setConstructionTask({ ...constructionTask });
  };

  const handleSubmit = () => {
    if (constructionTask.id === 0) {
      onTaskEdited(constructionTask);
      setConstructionTask(null);
    } else {
      updateTaskComments({ variables: { id: constructionTask.id, comments: constructionTask.comments } }).then(() => {
        onTaskEdited(constructionTask);
        setConstructionTask(null);
      });
    }
  };

  return (
    <Modal isOpen={constructionTask !== null}>
      {constructionTask && <ModalHeader>Edit Comments - Task Id: {constructionTask.id}</ModalHeader>}
      <ModalBody>
        {' '}
        {constructionTask && (
          <AvForm>
            <AvField
              type="textarea"
              name="comments"
              label="Comments"
              rows="5"
              onChange={handleChange}
              value={constructionTask.comments ? constructionTask.comments : ''}
            />
          </AvForm>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => handleSubmit()} type="submit" color="primary" className="px-4">
          Save
        </Button>{' '}
        <Button color="secondary" onClick={() => setConstructionTask(null)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TaskQuickEdit;
