import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/AuthProvider';

export function useFieldWork(group) {
  const [fieldwork, setFieldwork] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var url = group ? `/api/dashboard/fieldwork/${group}` : `/api/dashboard/fieldwork`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        });
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        setFieldwork(body);
      } catch (error) {
        console.error('Fetching fieldwork data failed:', error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [group, token]);

  return { fieldwork, loading };
}

export function useCompletedWork(group, months) {
  const [completedWork, setCompletedWork] = useState({});
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      var url = group ? `/api/dashboard/completed/${group}/${months}` : `/api/dashboard/completed/${months}`;
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        });
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        setCompletedWork(body);
      } catch (error) {
        console.error('Fetching completed work data failed:', error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [group, months]);

  return { completedWork, loading };
}
