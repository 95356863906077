import React, { useState, useEffect, Fragment } from 'react';
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { assign } from 'apollo-utilities';
import { browserName } from 'react-device-detect';
import TaskQuickEdit from './TaskQuickEdit';
import TaskReminderQuickEdit from './TaskReminderQuickEdit';
import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useAuth } from '../../auth/AuthProvider';
import moment from 'moment';
import 'moment-timezone';

export const GET_ACTIVEITEMCHILDCODES = gql`
  query subcontractorItemCodes(
    $fromDateUtc: DateOrTime
    $region: String
    $deliveryPartner: String
    $contractor: ID
    $codes: [String]
  ) {
    subcontractorItemCodes(
      region: $region
      deliveryPartner: $deliveryPartner
      contractor: $contractor
      fromDateUtc: $fromDateUtc
      codes: $codes
    ) {
      id
      contractorId
      parentCode
      childCode
      description
      unitOfMeasure
      region
      unitAmount
      startDateUtc
      itemType
      deliveryPartner
    }
  }
`;

function Schedule(props) {
  let {
    construction,
    updateSchedules,
    users,
    scheduleTypes,
    groups,
    taskRules,
    taskBlankIssued,
    taskFieldSettings,
    taskBlankDue
  } = props;
  const [schedules, setSchedules] = useState(props.construction.schedules);
  const [scheduleItemsToEdit, setScheduleItemsToEdit] = useState(undefined);
  const [unassignedBoqs, setUnassignedBoqs] = useState([]);
  const [commentEditing, setCommentEditing] = useState(null);
  const [reminderEditing, setReminderEditing] = useState(null);

  const canSchedule = scheduleTypes.filter((x) => x.canSchedule).map((x) => x.taskName);

  useEffect(() => {
    setSchedules(construction.schedules);
  }, [construction.schedules]);

  useEffect(() => {
    updateSchedules(schedules);
  }, [schedules]);

  useEffect(() => {
    if (construction.boqs) {
      // Map boqs to Id
      const newBoqsById = {};

      // Get boqs with unassigned qty

      const newUnassignedBoqs = [];
      construction.boqs.forEach((boq) => {
        const assignedQty = construction.schedules.reduce(
          (acc, v) => acc + (v.items?.find((i) => i.boqId === boq.id)?.quantity ?? 0),
          0
        );
        if (assignedQty < (boq.variationQty ?? boq.quantity)) {
          newUnassignedBoqs.push({
            ...boq,
            assignedQty,
            unassignedQty: (boq.variationQty ?? boq.quantity) - assignedQty
          });
        }
        newBoqsById[boq.id] = {
          ...boq,
          assignedQty,
          unassignedQty: (boq.variationQty ?? boq.quantity) - assignedQty
        };
      });
      setUnassignedBoqs(newUnassignedBoqs);
    } else {
      setUnassignedBoqs([]);
    }
  }, [construction.schedules]);

  const handleChange = (index, e) => {
    if (e.target.name === 'items') {
      schedules[index]['items'] = e.target.value;
    }

    if (e.target.type === 'select-one' && e.target.value === '') {
      schedules[index][e.target.name] = null;
    }

    if (e.target.name === 'resource') {
      if (e.target.value.indexOf('@') > -1 || e.target.value === '') {
        schedules[index]['groupId'] = null;
      } else if (e.target.value.indexOf(':') > -1) {
        var groupId = parseInt(e.target.value.split(':')[1]);
        schedules[index]['groupId'] = groupId;
      }
    }

    if (e.target.type === 'checkbox') {
      schedules[index][e.target.name] = e.target.checked;
    } else {
      schedules[index][e.target.name] = e.target.value;

      // make sure we null the date, not empty string
      if (e.target.type === 'date') {
        if (e.target.value == '') {
          schedules[index][e.target.name] = null;
        }

        // user is changing date
        if (e.target.name === 'issuedDate') {
          var date = new Date(e.target.value);

          // if the date is changed, we need may need to update the due date
          switch (schedules[index].name) {
            case 'DBYD':
              // dbyd is due 2 business days after issue
              schedules[index]['dueDate'] = addBusinessDays(date, 2).toISOString().substring(0, 10);
              break;
            case 'LASE':
              // the field pack needs to be issued 2 business days after the LASE issued date
              var field = schedules.find((s) => s.name === 'Field Pack');
              field.issuedDate = date.toISOString().substring(0, 10);
              field.dueDate = addBusinessDays(date, 2).toISOString().substring(0, 10);
              break;
            default:
              break;
          }
        }

        if (e.target.name === 'completedDate') {
          var date = new Date(e.target.value);

          if (date instanceof Date && !isNaN(date) && e.target.value.substring(0, 1) !== '0') {
            var completedTask = schedules[index].name.trim();
            const targetTasks = taskRules.filter((x) => x.completedTask === completedTask);
            targetTasks.forEach((target) => {
              var updateIndexes = getAllIndexes(
                schedules.map((x) => x.name.trim()),
                target.targetTask
              );
              updateIndexes.forEach((updateIndex) => {
                if (!schedules[updateIndex]['dueDate']) {
                  schedules[updateIndex]['dueDate'] = addBusinessDays(date, target.dueDateDays)
                    .toISOString()
                    .substring(0, 10);
                }
              });
            });
          }
        }
      }
    }

    setSchedules([...schedules]);
  };

  function getAllIndexes(arr, val) {
    var indexes = [],
      i;
    for (i = 0; i < arr.length; i++) if (arr[i] === val) indexes.push(i);
    return indexes;
  }

  //For whatever reason 'clear' doesn't fire an onChange event on dirty date fields, so lets force the logic
  const onDueDateBlur = (index, e) => {
    if (e.target.value === '') {
      schedules[index]['dueDate'] = null;
      setSchedules([...schedules]);
    }
  };

  const addItem = () => {
    setSchedules([
      ...schedules,
      {
        id: 0,
        name: null,
        initial: false,
        ignored: false,
        owner: null,
        resource: null,
        issuedDate: null,
        dueDate: null,
        completedDate: null,
        reminderDate: null,
        comments: null,
        items: []
      }
    ]);
  };

  const removeItem = (schedule, index) => {
    if (schedule.initial) {
      schedules[index].ignored = !schedule.ignored;
      if (schedule.ignored) {
        schedules[index].owner = null;
        schedules[index].resource = null;
        schedules[index].groupId = null;
        schedules[index].issuedDate = null;
        schedules[index].dueDate = null;
        schedules[index].completedDate = null;
        schedules[index].isReopened = false;
        schedules[index].onsiteDate = null;
        schedules[index].reminderDate = null;
        schedules[index].acceptedDate = null;
        schedules[index].declinedDate = null;
        schedules[index].items = [];
      } else {
        schedules[index].items = [];

        schedules[index].items = unassignedBoqs
          .filter((b) => b.itemType.trim().toLowerCase() === schedules[index].name?.toLowerCase())
          .map((b) => ({
            id: 0,
            boqId: b.id,
            quantity: (b.variationQty ?? b.quantity) - (b.assignedQty ?? 0)
          }));
      }
    } else {
      schedules.splice(index, 1);
    }
    setSchedules([...schedules]);
  };

  function checkDueDate(due, integration, resource) {
    let today = new Date();
    let dueDate = new Date(due);
    //let integrationDate = new Date(integration);

    if (resource === null || resource === '') {
      return '';
    }

    if (resource && resource !== '' && (!due || due === '')) {
      return 'bg-purple';
    }

    if (dueDate < today) {
      return 'bg-danger';
    }

    // if((integration && integration !== '' ) && dueDate > integrationDate) {
    //     return 'bg-danger';
    // }

    if (resource && resource !== '' && due && due !== '') {
      return 'bg-success';
    }

    return '';
  }

  function addBusinessDays(startDate, days) {
    if (isNaN(days)) {
      console.log('Value provided for "days" was not a number');
      return;
    }
    if (!(startDate instanceof Date)) {
      console.log('Value provided for "startDate" was not a Date object');
      return;
    }
    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);
    // If the current day is Sunday add one day
    if (dow == 0) daysToAdd++;
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
      //Subtract days in current working week from work days
      var remainingWorkDays = daysToAdd - (5 - dow);
      //Add current working week's weekend
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        //Add two days for each working week by calculating how many weeks are included
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
        if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
      }
    }

    // Add days to start date (but return a new date object - forgot javascipt non primitives is byRef)
    var date = new Date(startDate);
    date.setDate(date.getDate() + daysToAdd);
    return date;
  }

  const editScheduleItems = (evt, index) => {
    evt.preventDefault();

    setScheduleItemsToEdit(index);
  };

  const editTaskComments = (id, comments) => {
    setCommentEditing({ id, comments });
  };

  const taskCommentsEdited = (schedule) => {
    var _schedules = [...schedules];

    var i = _schedules.findIndex((x) => x.id === schedule.id);

    _schedules[i].comments = schedule.comments;

    setSchedules([..._schedules]);
  };

  const editTaskReminder = (id, reminderDate, reminderComments) => {
    setReminderEditing({ id, reminderDate, reminderComments });
  };

  const taskReminderEdited = (schedule) => {
    var _schedules = [...schedules];

    var i = _schedules.findIndex((x) => x.id === schedule.id);

    _schedules[i].reminderDate = schedule.reminderDate;
    _schedules[i].reminderComments = schedule.reminderComments;

    setSchedules([..._schedules]);
  };

  const formatDate = (dateTime) => {
    if (!dateTime) return null;
    const formattedDateTime = moment.utc(dateTime).tz(moment.tz.guess()).format('YYYY-MM-DD');
    return formattedDateTime;
  };

  return (
    <>
      <TaskQuickEdit constructionTask={commentEditing} onTaskEdited={taskCommentsEdited} />
      <TaskReminderQuickEdit constructionTask={reminderEditing} onTaskEdited={taskReminderEdited} />

      {schedules && (
        <>
          <AvForm>
            <Table responsive hover size="sm">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Task</th>
                  <th style={{ width: '135px' }}>Owner</th>
                  <th style={{ width: '135px' }}>Resource</th>
                  <th style={{ width: '85px' }}>Items</th>
                  {/* <th style={{ width: '25px' }}>$?</th> */}
                  <th style={{ width: '135px' }}>Issued</th>
                  <th style={{ width: '135px' }}>Due</th>
                  <th style={{ width: '135px' }}>Completed</th>
                  <th style={{ width: '135px' }}>Reminder</th>
                  <th>Comments</th>

                  <th style={{ width: '36px' }}></th>
                </tr>
              </thead>
              <tbody>
                {schedules.map((schedule, index) => {
                  return (
                    <tr
                      key={index}
                      className={schedule.ignored ? 'table-secondary' : ''}
                      style={schedule.completedDate && schedule.resource ? { backgroundColor: '#F1D02B' } : {}}
                    >
                      {schedule.id === 0 ? <td>NEW</td> : <td>{schedule.id}</td>}

                      {schedule.initial ? (
                        <td>{schedule.name}</td>
                      ) : (
                        <td>
                          {scheduleTypes.length && (
                            <select
                              className="form-control"
                              placeholder="Name"
                              required
                              name="name"
                              value={schedule.name ?? ''}
                              onChange={(e) => handleChange(index, e)}
                            >
                              <option value="">(pick an option...)</option>
                              {scheduleTypes.map((value, i) => (
                                <option key={i} value={value.taskName}>
                                  {value.taskName}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                      )}

                      {schedule.ignored ? (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      ) : (
                        <>
                          <td className={taskFieldSettings.includes('owner') && !schedule.owner ? 'bg-danger' : ''}>
                            {users.length > 0 && (
                              <select
                                style={{ width: '150px' }}
                                className="form-control"
                                placeholder="Owner"
                                required
                                name="owner"
                                value={schedule.owner ?? ''}
                                onChange={(e) => handleChange(index, e)}
                              >
                                <option value="">(pick an option...)</option>
                                <>
                                  {users
                                    .filter((user) => user.roles.includes('Project Management'))
                                    .map((value, index) => (
                                      <option key={value.email} value={value.email}>
                                        {value.displayName}
                                      </option>
                                    ))}
                                </>
                              </select>
                            )}
                          </td>
                          <td
                            className={taskFieldSettings.includes('resource') && !schedule.resource ? 'bg-danger' : ''}
                          >
                            {groups.length && (
                              <select
                                style={{ width: '150px' }}
                                className="form-control"
                                placeholder="Resource"
                                disabled={
                                  schedule.scheduledStartTime ||
                                  (canSchedule.includes(schedule.name) && !schedule.resource)
                                }
                                title={
                                  schedule.scheduledStartTime ||
                                  (canSchedule.includes(schedule.name) && !schedule.resource)
                                    ? 'Use the scheduling tool to re-schedule to another resource'
                                    : null
                                }
                                required
                                name="resource"
                                value={schedule.resource ?? ''}
                                onChange={(e) => handleChange(index, e)}
                              >
                                <option value="">
                                  {schedule.scheduledStartTime || canSchedule.includes(schedule.name)
                                    ? ''
                                    : '(pick an option...)'}
                                </option>

                                {groups
                                  .filter((x) =>
                                    x.tasks?.split(';').includes(construction.workType + ': ' + schedule.name)
                                  )
                                  .map((value, index) => (
                                    <option key={value.id} value={'group:' + value.id}>
                                      {value.name}
                                    </option>
                                  ))}

                                {schedule.resource && !schedule.resource.startsWith('group:') && (
                                  <>
                                    <option disabled>----USERS----</option>
                                    {users.map((value, index) => (
                                      <option key={value.email} value={value.email}>
                                        {value.displayName}
                                      </option>
                                    ))}
                                  </>
                                )}
                              </select>
                            )}
                          </td>

                          <td>
                            <a
                              href="#"
                              onClick={(e) => {
                                schedule.resource
                                  ? editScheduleItems(e, index)
                                  : toast(`Select a resource for ${schedule.name} first`, { type: 'warning' });
                                e.preventDefault();
                                return false;
                              }}
                            >
                              {(schedule.items?.length ?? 0) > 0 ? `${schedule.items.length} items` : `Select items`}
                            </a>
                          </td>
                          <td>
                            {!taskBlankIssued.includes(schedule.name) ? (
                              <input
                                style={{ width: '158px' }}
                                className="form-control"
                                type="date"
                                title={
                                  schedule.scheduledStartTime
                                    ? 'Use the scheduling tool to remove or re-issue this task'
                                    : null
                                }
                                disabled={schedule.scheduledStartTime}
                                value={formatDate(schedule.issuedDate) ?? ''}
                                name="issuedDate"
                                onChange={(e) => handleChange(index, e)}
                              />
                            ) : null}
                          </td>

                          <td
                            className={
                              schedule.completedDate
                                ? ''
                                : checkDueDate(schedule.dueDate, construction.integrationDate, schedule.resource)
                            }
                          >
                            {!taskBlankDue.includes(schedule.name) ? (
                              <input
                                name="dueDate"
                                style={{ width: '158px' }}
                                className="form-control"
                                type="date"
                                value={formatDate(schedule.dueDate) ?? ''}
                                onChange={(e) => handleChange(index, e)}
                                onBlur={(e) => onDueDateBlur(index, e)}
                              />
                            ) : null}
                          </td>

                          <td>
                            <input
                              style={{ width: '158px' }}
                              className="form-control"
                              type="date"
                              value={formatDate(schedule.completedDate) ?? ''}
                              name="completedDate"
                              onChange={(e) => handleChange(index, e)}
                            />
                          </td>

                          <td>
                            <div
                              style={{ width: '158px' }}
                              className="form-control"
                              onClick={() =>
                                editTaskReminder(schedule.id, schedule.reminderDate, schedule.reminderComments)
                              }
                            >
                              {schedule.reminderDate ? schedule.reminderDate : 'Set Reminder'}
                            </div>
                          </td>

                          <td>
                            <div
                              className="form-control"
                              style={{ overflow: 'hidden' }}
                              onClick={() => editTaskComments(schedule.id, schedule.comments)}
                            >
                              {schedule.comments}
                            </div>
                          </td>
                        </>
                      )}

                      <td>
                        {schedule.initial ? (
                          schedule.ignored ? (
                            <Button size="sm" tabIndex="-1" onClick={() => removeItem(schedule, index)}>
                              <i className="fa fa-plus-square"></i>
                            </Button>
                          ) : (
                            <Button size="sm" tabIndex="-1" onClick={() => removeItem(schedule, index)}>
                              <i className="fa fa-minus-square"></i>
                            </Button>
                          )
                        ) : (
                          <Button tabIndex="-1" onClick={() => removeItem(schedule, index)}>
                            <i className="fa fa-times"></i>
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Button onClick={addItem}>Add Item</Button>
          </AvForm>
        </>
      )}
      {(scheduleItemsToEdit || scheduleItemsToEdit === 0) && (
        <EditScheduleItems
          construction={construction}
          groups={groups}
          index={scheduleItemsToEdit}
          onClose={() => setScheduleItemsToEdit(undefined)}
          handleChange={handleChange}
        />
      )}
    </>
  );
}

const EditScheduleItems = ({ construction, groups, index, ...props }) => {
  const [boqsById, setBoqsById] = useState({});
  const [unassignedBoqs, setUnassignedBoqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubcontractor, setIsSubcontractor] = useState(false);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    // Determine if this is a subcontractor resource or not
    if (construction && groups) {
      const schedule = construction?.schedules?.[index];
      if (schedule) {
        if (schedule.resource?.startsWith('group:')) {
          const groupId = parseInt(schedule.resource.split(':')[1]);
          const group = groups.find((g) => g.id === groupId);
          if (group && group.isSubcontractor) {
            setIsSubcontractor(true);
          } else {
            setIsSubcontractor(false);
          }

          setLoading(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (construction.boqs) {
      // Map boqs by Id
      const newBoqsById = {};

      // Get boqs with unassigned qty
      const newUnassignedBoqs = [];

      const theSchedule = construction?.schedules?.[index];
      construction.boqs.forEach((boq) => {
        const hasBoq = !!theSchedule?.items?.find((i) => i.boqId === boq.id && (!i.childCode || i.childCode === ''));
        const assignedQty = construction.schedules.reduce(
          (acc, v) => acc + (v.items?.find((i) => i.boqId === boq.id)?.quantity ?? 0),
          0
        );
        if (!hasBoq && assignedQty < (boq.variationQty ?? boq.quantity)) {
          newUnassignedBoqs.push({
            ...boq,
            assignedQty,
            unassignedQty: (boq.variationQty ?? boq.quantity) - assignedQty
          });
        }
        newBoqsById[boq.id] = {
          ...boq,
          assignedQty,
          unassignedQty: (boq.variationQty ?? boq.quantity) - assignedQty
        };
      });
      setUnassignedBoqs(newUnassignedBoqs);
      setBoqsById(newBoqsById);

      let anyInvalid = !!theSchedule?.items?.find((item) => {
        const boq = newBoqsById[item.boqId];
        if (boq) {
          if (boq.unassignedQty < 0) {
            anyInvalid = true;
            return true;
          }
        }
      });
      setInvalid(anyInvalid);
    } else {
      setUnassignedBoqs([]);
    }
  }, [index, construction.schedules]);

  if (loading) {
    return null;
  } else if (isSubcontractor) {
    return (
      <EditScheduleBoqChildItemsModal
        construction={construction}
        index={index}
        groups={groups}
        boqsById={boqsById}
        unassignedBoqs={unassignedBoqs}
        isSubcontractor={isSubcontractor}
        {...props}
      />
    );
  } else {
    return (
      <EditScheduleBoqItemsModal
        construction={construction}
        index={index}
        groups={groups}
        boqsById={boqsById}
        unassignedBoqs={unassignedBoqs}
        isSubcontractor={isSubcontractor}
        {...props}
      />
    );
  }
};

const EditScheduleBoqItemsModal = ({ construction, index, boqsById, unassignedBoqs, ...props }) => {
  return (
    <EditScheduleItemsModal
      construction={construction}
      index={index}
      boqsById={boqsById}
      unassignedBoqs={unassignedBoqs}
      {...props}
    />
  );
};

const EditScheduleBoqChildItemsModal = ({ construction, index, boqsById, unassignedBoqs, ...props }) => {
  const schedule = construction?.schedules?.[index];

  // const { loading, data: childCodes, refetch, error } = useQuery(GET_ACTIVEITEMCHILDCODES, {fetchPolicy: 'no-cache', variables: { fromDateUtc: construction.releaseDate, contractor: schedule?.resource?.split(':')?.[1], codes: construction?.boqs?.map(b => b.code) } });
  const [getChildCodes, { loading, data: childCodes, refetch, error }] = useLazyQuery(GET_ACTIVEITEMCHILDCODES, {
    fetchPolicy: 'no-cache'
  });

  const [childItems, setChildItems] = useState();
  const [childItemsByCode, setChildItemsByCode] = useState({});
  const [filteredUnassignedBoqs, setFilteredUnassignedBoqs] = useState([]);
  const [unassignedChildItems, setUnassignedChildItems] = useState([]);

  useEffect(() => {
    getChildCodes({
      variables: {
        region: construction.region,
        fromDateUtc: construction.releaseDate,
        contractor: schedule?.groupId,
        deliveryPartner: construction.deliveryPartner,
        codes: construction?.boqs?.map((b) => b.code)
      }
    });
  }, [index, getChildCodes]);

  useEffect(() => {
    if (!loading && childCodes) {
      if (!childCodes.subcontractorItemCodes?.length) {
        toast(`No matching child item codes found`, { type: 'warning' });
        if (props.onClose) {
          props.onClose();
        }
      } else {
        let newChildItems = [];
        unassignedBoqs.forEach((boq) => {
          newChildItems = newChildItems.concat(
            childCodes.subcontractorItemCodes.filter(
              (item) => item.parentCode === boq && item.deliveryPartner === construction.deliveryPartner
            )
          );
        });

        setChildItems(newChildItems);
      }
    }
  }, [loading, childCodes, props?.onClose]);

  useEffect(() => {
    if (childItems) {
      const theSchedule = construction?.schedules?.[index];
      const newUnassignedChildItems = [];
      const newChildItemsByCode = {};
      const newFilteredUnassignedBoqs = [];
      construction.boqs.forEach((boq) => {
        const boqChildCodes = childCodes.subcontractorItemCodes.filter((item) => item.parentCode === boq.code);
        boqChildCodes.forEach((ci) => {
          const boq = construction.boqs.find((b) => b.code === ci.parentCode);

          const hasCI = boq && !!theSchedule?.items?.find((i) => i.boqId === boq?.id && i.childCode === ci.childCode);
          const inList = newUnassignedChildItems.find((x) => x.id === ci.id);

          if (!hasCI && !inList) {
            newUnassignedChildItems.push(ci);
          }

          newChildItemsByCode[`${ci.parentCode}-${ci.childCode}`] = ci;
        });
      });

      unassignedBoqs.forEach((boq) => {
        // Only include boqs that have child items available
        const boqChildCodes = childCodes.subcontractorItemCodes.filter((item) => item.parentCode === boq.code);
        if (boqChildCodes.length) {
          newFilteredUnassignedBoqs.push(boq);
        }
      });

      setChildItemsByCode(newChildItemsByCode);
      setUnassignedChildItems(newUnassignedChildItems);
      setFilteredUnassignedBoqs(newFilteredUnassignedBoqs);
    }
  }, [index, childItems]);

  if (loading) {
    return null;
  } else if (childItems) {
    return (
      <EditScheduleItemsModal
        construction={construction}
        index={index}
        boqsById={boqsById}
        unassignedBoqs={filteredUnassignedBoqs}
        childItemsByCode={childItemsByCode}
        unassignedChildItems={unassignedChildItems}
        {...props}
      />
    );
  } else {
    return null;
  }
};

const EditScheduleItemsModal = ({
  construction,
  index,
  handleChange,
  onClose,
  boqsById,
  unassignedBoqs,
  isSubcontractor,
  childItemsByCode = {},
  unassignedChildItems = []
}) => {
  const schedule = construction?.schedules?.[index];
  const [dropdownOpen, setDropdownOpen] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const [childDropdownOpen, setChildDropdownOpen] = useState({});
  const [scheduleItems, setScheduleItems] = useState(schedule?.items);
  const auth = useAuth();

  useEffect(() => {
    setScheduleItems(
      schedule?.items?.sort((a, b) => b.boqId - a.boqId || (a.childCode ?? '').localeCompare(b.childCode ?? ''))
    );
  }, [schedule?.items]);

  const handleItemChange = (bIndex, e) => {
    if (e.target.type === 'number') {
      schedule.items[bIndex][e.target.name] = e.target.value ? parseFloat(e.target.value) : undefined;
    } else {
      schedule.items[bIndex][e.target.name] = e.target.value;
    }

    handleChange(index, { target: { name: 'items', value: [...schedule.items] } });
  };

  const removeItem = (indx) => {
    const itemToRemove = scheduleItems[indx];
    scheduleItems.splice(indx, 1);

    let newScheduleItems = scheduleItems;
    if (itemToRemove && !itemToRemove.childCode) {
      newScheduleItems = newScheduleItems.filter((i) => i.boqId !== itemToRemove.boqId);
    } else if (itemToRemove && itemToRemove.childCode) {
      // If the last child item, remove the parent
      if (!newScheduleItems.filter((i) => i.boqId === itemToRemove.boqId && i.childCode).length) {
        newScheduleItems = newScheduleItems.filter((i) => i.boqId !== itemToRemove.boqId);
      }
    }

    handleChange(index, { target: { name: 'items', value: [...newScheduleItems] } });
  };

  const addItem = (boq) => {
    const items = scheduleItems ?? [];
    items.push({
      id: 0,
      boqId: boq.id,
      quantity: boq.unassignedQty
    });

    // Auto add the child items
    const boqUnassignedChildItems = unassignedChildItems.filter((ci) => ci.parentCode === boq.code);
    boqUnassignedChildItems.forEach((childItem) => {
      items.push({
        id: 0,
        boqId: boq.id,
        childCode: childItem.childCode,
        quantity: 0
      });
    });

    handleChange(index, { target: { name: 'items', value: [...items] } });
    setTimeout(() => setDropdownOpen(true), 250);
  };

  const addChildItem = (boq, item) => {
    const items = schedule.items ?? [];
    items.push({
      id: 0,
      boqId: boq.id,
      childCode: item.childCode,
      quantity: 0
    });
    handleChange(index, { target: { name: 'items', value: [...items] } });
    setTimeout(() => {
      childDropdownOpen[boq.id] = true;
      setChildDropdownOpen({ ...childDropdownOpen });
    }, 250);
  };

  return (
    <Modal
      isOpen={!!schedule}
      onClose={!invalid ? onClose : undefined}
      toggle={!invalid ? onClose : undefined}
      size="xl"
    >
      <ModalHeader>
        Edit items for {schedule?.id} {schedule?.name}
      </ModalHeader>
      <ModalBody>
        {schedule && (
          <AvForm>
            {!unassignedBoqs.length && !schedule.items?.length && <div>No unassigned items to add</div>}
            {(!!unassignedBoqs.length || !!schedule.items?.length) && (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th style={{ width: '130px' }}>Code</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>UOM</th>
                    <th>Quantity</th>
                    <th style={{ width: '130px' }}>Unassigned Qty</th>
                    {auth.me.roles.includes('Finance') && (
                      <th style={{ width: '100px', textAlign: 'right' }}>Rate $</th>
                    )}
                    {auth.me.roles.includes('Finance') && (
                      <th style={{ width: '100px', textAlign: 'right' }}>Planned $</th>
                    )}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {scheduleItems?.map((item, bIndex) => {
                    const boq = boqsById[item.boqId];
                    if (boq && !item.childCode) {
                      const boqUnassignedChildItems = unassignedChildItems.filter((ci) => ci.parentCode === boq.code);
                      return (
                        <Fragment key={boq.code}>
                          <tr
                            className={boq.unassignedQty < 0 ? 'table-danger' : ''}
                            style={isSubcontractor ? { backgroundColor: '#2596be66' } : null}
                          >
                            <td style={{ fontWeight: 700 }}>{boq.code}</td>
                            <td>{boq.description}</td>
                            <td>{boq.itemType}</td>
                            <td>{boq.unitOfMeasure}</td>
                            <td>
                              <input
                                className={`form-control ${
                                  (item.quantity ?? 0) <= 0 ? 'text-danger font-weight-bold' : ''
                                }`}
                                type="number"
                                id={`${bIndex}-quantity`}
                                name="quantity"
                                value={item.quantity ?? 0}
                                onChange={(e) => handleItemChange(bIndex, e)}
                              />
                            </td>
                            <td>{boq.unassignedQty}</td>
                            {auth.me.roles.includes('Finance') && (
                              <td style={{ textAlign: 'right' }}>
                                {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(
                                  boq.unitAmount
                                )}
                              </td>
                            )}
                            {auth.me.roles.includes('Finance') && (
                              <td style={{ textAlign: 'right' }}>
                                {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(
                                  boq.unitAmount && item.quantity ? boq.unitAmount * item.quantity : 0
                                )}
                              </td>
                            )}
                            <td>
                              <Button tabIndex="-1" onClick={() => removeItem(bIndex)}>
                                X
                              </Button>
                            </td>
                          </tr>
                          {boqUnassignedChildItems?.length > 0 && (
                            <tr>
                              <td colSpan={7}>
                                <Dropdown
                                  isOpen={childDropdownOpen[item.boqId]}
                                  style={{ marginTop: '10px' }}
                                  toggle={() => {
                                    childDropdownOpen[item.boqId] = !childDropdownOpen[item.boqId];
                                    setChildDropdownOpen({ ...childDropdownOpen });
                                  }}
                                >
                                  <DropdownToggle caret>Add Child Item</DropdownToggle>
                                  <DropdownMenu container="body">
                                    {boqUnassignedChildItems.map((childItem, index) => (
                                      <DropdownItem
                                        key={childItem.parentCode + childItem.childCode + index}
                                        onClick={() => addChildItem(boq, childItem)}
                                      >
                                        {childItem.childCode} - {childItem.itemType}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      );
                    } else if (boq && item.childCode) {
                      // find child code item
                      const childKey = `${boq.code}-${item.childCode}`;
                      const childItem = childItemsByCode[childKey];
                      return (
                        <tr key={childKey}>
                          <td style={{ fontWeight: 700 }}>{item.childCode}</td>
                          <td>{childItem?.description}</td>
                          <td>{childItem?.itemType}</td>
                          <td>{childItem?.unitOfMeasure}</td>
                          <td>
                            <input
                              className={`form-control ${
                                (item.quantity ?? 0) <= 0 ? 'text-danger font-weight-bold' : ''
                              }`}
                              type="number"
                              id={`${bIndex}-quantity`}
                              name="quantity"
                              value={item.quantity ?? 0}
                              onChange={(e) => handleItemChange(bIndex, e)}
                            />
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <Button tabIndex="-1" onClick={() => removeItem(bIndex)}>
                              X
                            </Button>
                          </td>
                        </tr>
                      );
                    }

                    return null;
                  })}
                </tbody>
              </Table>
            )}
            {unassignedBoqs?.length > 0 && (
              <>
                <Dropdown
                  isOpen={dropdownOpen}
                  style={{ marginTop: '10px' }}
                  toggle={() => setDropdownOpen(!dropdownOpen)}
                >
                  <DropdownToggle caret>Add Item</DropdownToggle>
                  <DropdownMenu container="body">
                    {unassignedBoqs.map((boq) => (
                      <DropdownItem key={boq.id} onClick={() => addItem(boq)}>
                        {boq.code} - {boq.itemType}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </>
            )}
          </AvForm>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={invalid} onClick={!invalid ? onClose : undefined}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default Schedule;
