import React, { useEffect, useState, useRef, useCallback } from 'react';
import useGeoLocation from 'react-hook-geolocation';
import { useParams, useHistory } from 'react-router-dom';
import { GET_CONSTRUCTION } from './Construction';
import { useQuery } from '@apollo/react-hooks';
import { db } from '../../utils/db';
import Sync from './Sync';
import { getAddress } from '../../utils/utilFunctions';

const Capture = (props) => {
  const { id, taskId } = useParams();
  const [construction, setConstruction] = useState(null);
  const [validFilename, setValidFilename] = useState(false);

  const { data } = useQuery(GET_CONSTRUCTION, { variables: { constructionId: id }, fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data && data.construction) {
      setConstruction(data.construction);
      setTags([...data.construction.schedules.filter((s) => s.id == taskId).map((s) => s.name)]);
      setAddress(getAddress(data.construction));
    }
  }, [data]);

  const successBorder = '1px solid #28a745';
  const errorBorder = '1px solid #dc3545';
  const warningBorder = '1px solid #ffc107';

  const successBackground = '#b9eec5';
  const errorBackground = '#f8d7da';
  const warningBackground = '#fff3cd';

  const history = useHistory();

  const canvasRef = useRef(null);

  const [capturedImage, setCapturedImage] = useState(null);

  const [address, setAddress] = useState('');
  const [tags, setTags] = useState([]);
  const [comments, setComments] = useState('');
  const [filename, setFileName] = useState('');
  const [imageNumber, setImageNumber] = useState(1);

  const geolocation = useGeoLocation({ enableHighAccuracy: true, maximumAge: 10000, timeout: 5000 });

  const lastComment = JSON.parse(sessionStorage.getItem('last-image-comment'));
  let storedComment = '';
  if (lastComment?.taskId === taskId) {
    storedComment = lastComment.comments;
  }

  useEffect(() => {
    console.log(geolocation);
  }, [geolocation]);

  useEffect(() => {
    var good = true;

    if (!filename || /^\s*$/.test(filename)) {
      good = false;
    }

    setValidFilename(good);
  }, [filename]);

  useEffect(() => {
    if (capturedImage !== null) {
      // now we can save it for syncing later
    }
  }, [capturedImage]);

  const drawTitle = (context, title) => {
    // update the file name
    let width = context.canvas.width;
    context.fillStyle = '#000000';
    context.fillRect(0, 0, width, 20);
    context.fillStyle = '#ffffff';
    context.font = '16px Arial';

    const text = `${title ?? ''} - [${tags.join(', ').trim()}]`;
    context.fillText(text, 5, 16);
  };

  const capture = async (btn) => {
    // raise click event on the #camera input
    document.getElementById('camera').click();
  };

  // this is triggered each time a new image is selected
  const cameraChange = () => {
    var file = document.getElementById('camera').files[0];
    if (!!file) {
      var blob_url = URL.createObjectURL(file);

      // get image height and width from blob_url
      var img = new Image();
      img.src = blob_url;
      img.onload = function () {
        let width = img.width;
        let height = img.height;

        // keep the same aspect ratio, but limit width/height to max of 1024
        if (width > height) {
          if (width > 1024) {
            height = Math.round((height * 1024) / width);
            width = 1024;
          }
        } else {
          if (height > 1024) {
            width = Math.round((width * 1024) / height);
            height = 1024;
          }
        }

        const canvas = canvasRef.current;
        var context = canvas.getContext('2d');

        context.canvas.width = width;
        context.canvas.height = height + 60;

        var raw = new Image();
        raw.src = blob_url;
        raw.onload = function () {
          // draw the image on the canvas
          context.drawImage(raw, 0, 40, width, height);
          // draw the deets on the canvas
          drawDeets(context);
          // draw the title on the canvas
          drawTitle(context, filename + ' #' + imageNumber);
          // get the data url from the canvas
          var data = canvas.toDataURL('image/jpeg');
          // set the image state to the data url
          setCapturedImage(data);
          saveImage(data, filename + '-' + imageNumber);
        };
      };
    }
  };

  const deleteOldPhotoHistory = () => {
    db.photoHistory
      .toArray()
      .then((x) => {
        return x[x.length - 1].id;
      })
      .then((lastId) => {
        db.photoHistory
          .where('id')
          .below(lastId - 99)
          .delete();
      });
  };

  const saveImage = async (image, name) => {
    var imageData = dataURItoBlob(image);

    try {
      await db.photos.add({
        name: name + '.jpg',
        description: comments,
        blob: image,
        url: null,
        tags: tags,
        size: imageData.size,
        constructionId: construction.id
      });

      const photo = await db.photos.orderBy('id').last();
      const createdDate = new Date().toISOString().split('.')[0];
      db.photoHistory.add({
        id: photo.id,
        name: name,
        size: imageData.size,
        constructionId: construction.id,
        createdDate: createdDate,
        lastUpdated: createdDate
      });
      deleteOldPhotoHistory();
    } catch (e) {
    } finally {
      // actually, we'll leave the preview there for a bit
      setImageNumber((prev) => prev + 1);
      setCapturedImage(null);
    }
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const drawDeets = (context) => {
    const width = context.canvas.width;
    const height = context.canvas.height;

    context.fillStyle = '#000000';

    // fill header and footer
    context.fillRect(0, 0, width, 40);
    context.fillRect(0, height - 20, width, 20);

    context.fillStyle = '#ffffff';
    context.font = '16px Arial';

    let bottomTextLocationY = height - 4;
    let bottomTextLocationX = width - 170;

    let text = `${construction.workOrder} - ${address}`;
    context.fillText(text, 5, 36);

    var location =
      Math.round(geolocation.latitude * 10000) / 10000 +
      ' ' +
      Math.round(geolocation.longitude * 10000) / 10000 +
      ' Accuracy: ' +
      Math.round(geolocation.accuracy) +
      'm';
    context.fillText(location || '', 5, bottomTextLocationY);

    var date = new Date();
    context.fillText(
      date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
      bottomTextLocationX,
      bottomTextLocationY
    );
  };

  const cancelImage = () => {};

  const done = useCallback(() => {
    setTimeout(() => {
      history.push('/field/construction/' + id + '/' + taskId);
    }, 100);
  }, [history, id, taskId]);

  return (
    <>
      <div className="pt-1 pb-3 h-100 d-inline-block">
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100vw',
            height: 'calc(100vh - 260px)',
            overflow: 'auto'
          }}
        >
          <div>
            <Sync hidden constructionId={id} />
          </div>
        </div>

        <canvas id="canvas" style={{ display: 'none' }} ref={canvasRef} />

        <input
          type="file"
          accept="image/*"
          capture="environment"
          id="camera"
          onChange={() => cameraChange()}
          style={{ display: 'none' }}
        />

        <div style={{ position: 'absolute', backgroundColor: '#e4e5e6', left: '0', bottom: '80px', width: '100vw' }}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="text-warn form-control"
                    style={{ border: '1px #ccc solid' }}
                    value={address}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group has-validation">
                  <label htmlFor="location" className="form-label">
                    Location
                  </label>
                  <input
                    type="text"
                    id="location"
                    className={
                      geolocation?.error
                        ? `text-danger border-danger form-control is-invalid`
                        : `form-control border-success text-success is-valid`
                    }
                    style={{ border: '1px #ccc solid' }}
                    value={
                      Math.round(geolocation?.latitude * 10000) / 10000 +
                        ' ' +
                        Math.round(geolocation?.longitude * 10000) / 10000 +
                        ' ' +
                        Math.round(geolocation?.accuracy * 10) / 10 +
                        'm' ?? `${geolocation?.error.code} - ${geolocation?.error.message}`
                    }
                    readOnly={true}
                  />
                  {geolocation?.error && <span className="text-danger">{geolocation?.error.message}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="filename" className="form-label required">
                    Title
                  </label>
                  <input
                    type="text"
                    id="filename"
                    maxLength={32}
                    className="form-control"
                    value={filename ?? ''}
                    placeholder="e.g. pit"
                    onChange={(e) => {
                      setFileName(e.target.value === '' ? null : e.target.value);
                      setImageNumber(1);
                    }}
                  />
                </div>
              </div>

              <div className="col">
                <div className="form-group">
                  <label htmlFor="imageCount" className="form-label">
                    Next Image #
                  </label>
                  <input
                    type="number"
                    id="imageCount"
                    className="form-control"
                    min={1}
                    value={imageNumber || 1}
                    placeholder="Next image number"
                    onChange={(e) => {
                      if (!isNaN(parseInt(e.target.value))) {
                        setImageNumber(parseInt(e.target.value ?? 1));
                      } else {
                        setImageNumber(1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label htmlFor="comments" className="form-label">
                    Description/Comments
                  </label>
                  <textarea
                    rows="2"
                    id="comments"
                    className="form-control"
                    value={comments}
                    placeholder="Add any comments here"
                    onChange={(e) => setComments(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ position: 'absolute', backgroundColor: '#e4e5e6', left: '0', bottom: '0', width: '100vw' }}>
        <div className="container">
          <div className="row pt-3 pb-3">
            <div className="col-6">
              <button disabled={!validFilename} className="btn btn-primary w-100 p-3" onClick={capture}>
                Capture new image
              </button>
            </div>

            <div className="col-6">
              <button className="btn btn-secondary w-100 p-3" onClick={done}>
                Done
              </button>
            </div>
          </div>
        </div>

        {capturedImage && (
          <table className="table m-0">
            <tbody>
              <tr className="table-warning">
                <td className="p-0">
                  <div
                    style={{
                      display: 'inline-block',
                      width: '80px',
                      height: '80px',
                      margin: '5px',
                      backgroundColor: '#000000',
                      backgroundImage: `url(${capturedImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                      backgroundPosition: 'center'
                    }}
                  ></div>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default Capture;
