import React from 'react';
import { Spinner } from 'reactstrap';

export const Loading = () => {
  return (
    <div className="text-center">
      <Spinner color="primary" className="mt-5" />
    </div>
  );
};
