import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import initialiseState from './utils/store';
import { ProvideAuth } from './auth/AuthProvider';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import ErrorBoundary from './views/FieldWork/ErrorBoundary';

initialiseState();

// Provide a global error handler
window.onerror = (message, url, line, column, error) => {
  // Step 1: Create the message element (div)
  const messageElement = document.createElement('div');

  // Optional: Add some styles to the message div
  messageElement.style.position = 'relative';
  messageElement.style.fontSize = '16px';
  messageElement.style.padding = '10px';
  messageElement.style.textAlign = 'left';
  messageElement.style.backgroundColor = '#f0f0f0';
  messageElement.style.border = '1px solid #ccc';
  messageElement.style.marginBottom = '10px';

  // Step 2: Create the pre element for preformatted text
  const preElement = document.createElement('pre');
  preElement.textContent = message + '\r\n' + error?.stack;

  // Step 3: Create the a element for the email link
  const mailtoLink = document.createElement('a');
  mailtoLink.href =
    'mailto:melissa.ferreri@adtechcontracting.com.au?subject=LeQuinn error&body=' +
    encodeURIComponent(message + '\r\n' + error?.stack);
  mailtoLink.textContent = 'Email this error';

  // Step 4: Append the pre and a elements to the message div
  messageElement.appendChild(preElement);
  messageElement.appendChild(mailtoLink);

  // Step 5: Create the close button
  var closeButton = document.createElement('a');
  closeButton.textContent = 'X';
  closeButton.href = '#';

  // Optional: Style the close button
  closeButton.style.position = 'absolute';
  closeButton.style.right = '10px';
  closeButton.style.bottom = '10px';
  closeButton.style.fontSize = '20px';
  closeButton.style.textDecoration = 'none';

  // Step 6: Add event listener to the close button
  closeButton.addEventListener('click', function (event) {
    event.preventDefault();
    messageElement.remove();
  });

  // Step 7: Append the close button to the message div
  messageElement.appendChild(closeButton);

  // Step 8: Select the body element
  const bodyElement = document.body;

  // Step 9: Insert the message div at the top of the body
  bodyElement.insertBefore(messageElement, bodyElement.firstChild);
};

ReactDOM.render(
  <ErrorBoundary>
    <ProvideAuth>
      <ServiceWorkerWrapper />
      <App />
    </ProvideAuth>
  </ErrorBoundary>,
  document.getElementById('root')
);
