import React, {useState, useEffect} from 'react';

const Version = () => {

  const [version, setVersion] = useState('');

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const response = await fetch('/auth/version');
        const data = await response.text();
        setVersion(data);
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    };

    fetchVersion();
  }, []);

  return (<span>{version}</span>)
}

export default Version
