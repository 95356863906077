import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useMutation } from '@apollo/react-hooks';

const UPDATE_TASKREMINDER = gql`
  mutation UpdateConstructionTaskReminder($id: Int!, $reminderDate: DateOrTime, $reminderComments: String) {
    updateConstructionTaskReminder(id: $id, reminderDate: $reminderDate, reminderComments: $reminderComments) {
      id
      reminderDate
      reminderComments
    }
  }
`;

const TaskReminderQuickEdit = (props) => {
  const { onTaskEdited } = props;
  const [updateTaskReminder, {}] = useMutation(UPDATE_TASKREMINDER);

  const [constructionTask, setConstructionTask] = useState(null);

  useEffect(() => {
    if (props.constructionTask !== null) {
      setConstructionTask(props.constructionTask);
    }
  }, [props.constructionTask]);

  const handleChange = (e) => {
    if (e.target.value === '') {
      constructionTask[e.target.name] = undefined;
    } else {
      constructionTask[e.target.name] = e.target.value;
    }

    setConstructionTask({ ...constructionTask });
  };

  const handleSubmit = () => {
    updateTaskReminder({
      variables: {
        id: constructionTask.id,
        reminderDate: constructionTask.reminderDate,
        reminderComments: constructionTask.reminderComments
      }
    }).then(() => {
      onTaskEdited(constructionTask);
      setConstructionTask(null);
    });
  };

  return (
    <Modal isOpen={constructionTask !== null}>
      {constructionTask && <ModalHeader>Edit Reminder - Task Id: {constructionTask.id}</ModalHeader>}
      <ModalBody>
        {' '}
        {constructionTask && (
          <AvForm>
            <AvField
              type="date"
              name="reminderDate"
              label="Reminder Date"
              onChange={handleChange}
              value={constructionTask.reminderDate ? constructionTask.reminderDate : ''}
            />
            <AvField
              type="textarea"
              name="reminderComments"
              label="Comments"
              rows="5"
              onChange={handleChange}
              value={constructionTask.reminderComments ? constructionTask.reminderComments : ''}
            />
          </AvForm>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => handleSubmit()} type="submit" color="primary" className="px-4">
          Save
        </Button>{' '}
        <Button color="secondary" onClick={() => setConstructionTask(null)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TaskReminderQuickEdit;
