import React, { useState, useEffect, useContext, createContext } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { isMobile } from 'react-device-detect';

var config = {
  apiKey: 'AIzaSyAPm1NxO4NqAPVQH5uNT63gR0oKmZTF3jU',
  authDomain: 'lequinn-ce0b2.firebaseapp.com',
  databaseURL: 'https://lequinn-ce0b2.firebaseio.com',
  projectId: 'lequinn-ce0b2',
  storageBucket: 'lequinn-ce0b2.appspot.com',
  messagingSenderId: '308383702687',
  appId: '1:308383702687:web:9514608070a4f3d1bb8caa',
  measurementId: 'G-YRB6V94H9Y'
};

firebase.initializeApp(config);

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [me, setMe] = useState(null);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [meFailed, setMeFailed] = useState(false);

  useEffect(() => {
    let cancelled = false;

    const interval = setInterval(() => {
      if (!cancelled) {
        getToken();
      }
    }, 60000);

    return () => {
      clearInterval(interval);
      cancelled = true;
    };
  }, [token, getToken]);

  const updateMe = (meObj) => {
    setMe(meObj);
    setLoading(false);
  };

  const isInRole = (role) => {
    return me !== null && me.roles.includes(role);
  };

  const impersonate = (id) => {
    fetch(`/auth/impersonate/${id}`, { method: 'POST', headers: { authorization: `Bearer ${token}` } })
      .then((response) => response.json())
      .then((data) => {
        setIsImpersonating(true);

        setMe(data.user);
        setToken(data.token);
      });
  };

  const signin = () => {

    // this doesn't even get called. It's all in Login.js (firebaseUIstyled bits)

    setLoading(true);
    const provider = new firebase.auth.OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      // Force account selection.
      prompt: 'select_account',
      tenant: 'common'
    });

    if (isMobile) {
      return firebase.auth().signInWithRedirect(provider);
    }

    return firebase.auth().signInWithPopup(provider);
  };

  const getToken = () => {
    if (firebase.auth().currentUser) {
      return firebase
        .auth()
        .currentUser.getIdToken() // don't force refresh
        .then(function (idToken) {
          setToken(idToken);
          return idToken;
        })
        .catch(function (error) {});
    }
  };

  const refreshToken = () => {
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          setToken(idToken);
        })
        .catch(function (error) {
          // Handle error
          console.log(error);
          setLoading(false);
        });
    } else {
      console.log('can not refresh token, no auth().currentUser');
    }
  };

  const signout = () => {

    setMeFailed(false);

    return firebase
      .auth()
      .signOut()
      .then(() => {
        fetch('/auth/signout', { method: 'POST' }).then(() => {
          setUser(null);
          setToken(null);
          window.location.replace('/');
        });
      });
  };

  // Subscribe to user on mount
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      //console.log({ authStateChanged: user });
      //console.log(firebase.auth().currentUser);

      if (user) {
        setUser(user);
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // We need loading to be true here, otherwise we do not correctly
            // trigger side-effects upon login change
            setLoading(true);
            // we sort of expect 403 here, but we'll get the cookie set by asp.net identity
            fetch('/auth/signin', { method: 'POST', headers: { authorization: `Bearer ${idToken}` } }).then(() => {
              setToken(idToken);
              setLoading(false);
            }).catch((err) => {
              console.error(err);
              setLoading(false);
            });
          })
          .catch(function (error) {
            // Handle error
            setLoading(false);
          });
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged((user) => {
      // instead of refreshToken (which forces a refresh), we'll just get the token
      getToken();
    });

    return () => unsubscribe();
  }, [token]);

  return {
    me,
    meFailed,
    user,
    token,
    loading,
    signin,
    signout,
    updateMe,
    getToken,
    setMeFailed,
    refreshToken,
    isInRole,
    impersonate,
    isImpersonating
  };
}
