export const sortJobs = (_jobs) => {
  return _jobs.sort((a, b) => {
    if (a.postCode !== b.postCode) {
      return safeStringCompare(a.postCode, b.postCode);
    }
    if (a.suburb !== b.suburb) {
      return safeStringCompare(a.suburb, b.suburb);
    }
    if (a.streetName !== b.streetName) {
      return safeStringCompare(a.streetName, b.streetName);
    }
    if ((a.streetNumber || 0) !== (b.streetNumber || 0)) {
      return (parseInt(a.streetNumber, 10) || 0) - (parseInt(b.streetNumber, 10) || 0);
    }
    if ((a.lotNumber || 0) !== (b.lotNumber || 0)) {
      return (parseInt(a.lotNumber, 10) || 0) - (parseInt(b.lotNumber, 10) || 0);
    }
    if (a.unitNumber !== b.unitNumber) {
      return safeStringCompare(a.unitNumber, b.unitNumber);
    }
    return 0;
  });
};

export const safeStringCompare = (str1, str2) => {
  str1 = str1 || '';
  str2 = str2 || '';
  return str1.localeCompare(str2);
};

export const getIsValidPhoneNumber = (phoneNumber) => {
  const e164Regex = /^\+614\d{8}$/;
  return e164Regex.test(phoneNumber);
};

export const convertPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;

  let phone = phoneNumber.replace(/\s+/g, ''); // Remove spaces
  if (phone.startsWith('04')) {
    phone = '+61' + phone.slice(1); // Modify to international format
  }
  return phone;
};
