import React, { useEffect, useState, useRef, useCallback } from 'react';
import useGeoLocation from 'react-hook-geolocation';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../auth/AuthProvider';
import { GET_CONSTRUCTION } from './Construction';
import { useQuery } from '@apollo/react-hooks';
import { db } from '../../utils/db';
import Sync from './Sync';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Helmet} from "react-helmet";

const navPlatform = navigator.userAgentData?.platform ?? navigator.platform;
const isIOS = /iPad|iPhone|iPod/.test(navPlatform)
|| (navPlatform === 'MacIntel' && navigator.maxTouchPoints > 1)

const stopMediaStream = (video) => {
  try {
    if (video) {
      video.pause();

      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => track.stop());
        video.srcObject = null; // will this clear it?
        video.src = "";
        video.load();
      }
    }
  } catch (e) {
    console.log('error stopping video', e);
  }
};

const Capture = (props) => {
  const { id, taskId } = useParams();
  const [construction, setConstruction] = useState(null);
  const [captureError, setCaptureError] = useState(null);

  const { data } = useQuery(GET_CONSTRUCTION, { variables: { constructionId: id }, fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (data && data.construction) {
      setConstruction(data.construction);
      setTags([...data.construction.schedules.filter((s) => s.id == taskId).map((s) => s.name)]);
    }
  }, [data]);

  const history = useHistory();

  const auth = useAuth();

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [image, setImage] = useState(null);
  const [tags, setTags] = useState([]);

  const [comments, setComments] = useState('');
  const [filename, setFileName] = useState('');
  const [flash, setFlash] = useState(false);
  const [valid, setValid] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [useMulti, setUseMulti] = useState(true);
  const [imageNumber, setImageNumber] = useState(1);
  const [isPortrait, setIsPortrait] = useState(true);
  const [preview, setPreview] = useState(false);
  const [readyFlag, setReadyFlag] = useState(true);
  const [successFlag, setSuccessFlag] = useState(false);

  const geolocation = useGeoLocation({ enableHighAccuracy: true, maximumAge: 15000, timeout: 12000 });

  const lastComment = JSON.parse(sessionStorage.getItem('last-image-comment'));
  let storedComment = '';
  if (lastComment?.taskId === taskId) {
    storedComment = lastComment.comments;
  }

  useEffect(() => {
    getVideo();

    return () => {
      // Make sure the video is stopped when unmounted
      if (videoRef.current) {
        try {
          stopMediaStream(videoRef.current);
          videoRef.current = null;
          canvasRef.current = null;
        } catch (e) {
        }
      }
    }
  }, [videoRef]);

  useEffect(() => {
    let video = videoRef.current;

    if (video !== null && video.srcObject !== null) {
      const track = video.srcObject.getTracks()[0];
      track.applyConstraints({ advanced: [{ torch: flash }] });
    }
  }, [flash]);

  // https://jsfiddle.net/39puneug/4/

  const getVideo = () => {
    if (videoRef.current && videoRef.current.srcObject && videoRef.current.srcObject.active) {
      // Only start the video if it's not already started
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ video: { width: 480, facingMode: { exact: 'environment' } }, audio: false })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        if (err?.constraint === 'facingMode') {
          // If exact: "environment" unavailable, just ignore facingMode constraint (allows working on desktop)
          navigator.mediaDevices
            .getUserMedia({ video: { width: 480 }, audio: false })
            .then((stream) => {
              let video = videoRef.current;
              video.srcObject = stream;
              video.play();
            })
            .catch((err) => {
              console.error('error:', err);
            });
        } else {
          console.error('error:', err);
        }
      });
  };

  useEffect(() => {
    var good = true;

    if (!filename || /^\s*$/.test(filename)) {
      good = false;
    }

    setValid(good);

    const canvas = canvasRef.current;
    if (image) {
      const context = canvas.getContext('2d');
      drawDeets(context, isPortrait);
      drawTitle(context, filename, isPortrait);
      const data = canvas.toDataURL('image/png');
      setImage(data);
    }
  }, [filename, tags, image, isPortrait]);

  useEffect(() => {
    if (successFlag) {
      const timer = setTimeout(() => {
        setSuccessFlag(false);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [successFlag]);

  const drawTitle = (context, title, portrait) => {
    // update the file name
    let width = portrait ? 480 : 700;
    context.fillStyle = '#000000';
    context.fillRect(0, 0, width, 20);
    context.fillStyle = '#ffffff';
    context.font = '16px Arial';

    const text = `${title ?? ''} - [${tags.join(', ').trim()}]`;
    context.fillText(text, 5, 16);
  };

  const capture = async (btn) => {
    if (btn) {
      btn.disabled = true;
    }
    setReadyFlag(false);
    try {
      const video = videoRef.current;
      let portrait = video.clientWidth < video.clientHeight;
      setIsPortrait(portrait);
      const canvas = canvasRef.current;

      const context = canvas.getContext('2d');
      context.canvas.height = portrait ? 700 : 480;
      context.canvas.width = portrait ? 480 : 700;

      if (portrait) {
        context.drawImage(video, 0, 40, 480, 680);
      } else {
        context.drawImage(video, 0, 40, 700, 460);
      }

      drawDeets(context, portrait);

      if (useMulti && filename && imageNumber) {
        const name = `${filename} #${imageNumber}`;
        drawTitle(context, name, portrait);
        const data = canvas.toDataURL('image/png');
        await checkImage(data, name);
        //saveImage(data, name);
        //setImageNumber(imageNumber + 1);
      } else {
        const data = canvas.toDataURL('image/png');
        setFlash(false);
        stopCamera();
        setImage(data);
      }
    } finally {
      setTimeout(() => {
        if (btn) {
          btn.disabled = false;
        }
      }, 250);

      setReadyFlag(true);
    }
  };

  const checkImage = async (image, name) => {
    var imageData = dataURItoBlob(image);
    // a blank image is about 11kB
    if (imageData.size > 30000) {
      await saveImage(image, name);
    } else {
      setPreview({ image: image, name: name });
    }
  };

  const imageIsOk = () => {
    //var imageData = dataURItoBlob(preview.image);
    saveImage(preview.image, preview.name);
    setPreview(null);
  };

  const toggleStatus = (tag) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags(tags.concat(tag));
    }
  };

  const updateTitle = async () => {
    setShowTitle(false);
  };

  const deleteOldPhotoHistory = () => {
    db.photoHistory
      .toArray()
      .then((x) => {
        return x[x.length - 1].id;
      })
      .then((lastId) => {
        db.photoHistory
          .where('id')
          .below(lastId - 99)
          .delete();
      });
  };

  const saveImage = async (image, name) => {
    var imageData = dataURItoBlob(image);

    try {
      await db.photos.add({
        name: name + '.png',
        description: comments,
        blob: image,
        url: null,
        tags: tags,
        size: imageData.size,
        constructionId: construction.id
      });

      const photo = await db.photos.orderBy('id').last();
      const createdDate = new Date().toISOString().split('.')[0];
      db.photoHistory.add({
        id: photo.id,
        name: name,
        size: imageData.size,
        constructionId: construction.id,
        createdDate: createdDate,
        lastUpdated: createdDate
      });
      deleteOldPhotoHistory();
      setSuccessFlag(true);
    } catch (e) {
      setCaptureError(e.message);
    } finally {
      setImage(null);
      getVideo();
      setImageNumber((prev) => prev + 1);
    }

    // var imageData = dataURItoBlob(image);

    // const {attachment, uploadUrl} = await getPresignedUploadParams({name: filename.length === 0 ? 'field.png' : filename + '.png', type: 'image/png', size: imageData.size, tags: tags, comments: comments });

    // uploadAttachment(uploadUrl, imageData).then(result => {
    //   toast('Image saved', { type: 'success' });

    //   setImage(null);
    //   getVideo(); // which starts camera again

    // }).catch(err => {
    //   toast('Issue saving image, try again', { type: 'warning' });
    // });
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  const getPresignedUploadParams = ({ name, size, tags, comments }) => {
    return fetch('/api/attachments/getPresignedUploadParams', {
      method: 'POST',
      body: JSON.stringify({ name, sizeBytes: size, constructionId: construction.id, tags: tags, comments: comments }),
      headers: { 'Content-Type': 'application/json', authorization: `Bearer ${auth.token}` }
    }).then((res) => res.json());
  };

  const uploadAttachment = (uploadUrl, imageData) => {
    return fetch(uploadUrl, {
      method: 'PUT',
      body: imageData,
      headers: { 'Content-Type': 'image/png' }
    });
  };

  useEffect(() => {
    // drawDeets();
  }, [geolocation]);

  const drawDeets = (context, portrait) => {
    context.fillStyle = '#000000';
    if (portrait) {
      context.fillRect(0, 0, 480, 40);
      context.fillRect(0, 680, 480, 20);
    } else {
      context.fillRect(0, 0, 700, 40);
      context.fillRect(0, 460, 700, 20);
    }

    context.fillStyle = '#ffffff';
    context.font = '16px Arial';

    let bottomTextLocationY = portrait ? 696 : 476;
    let bottomTextLocationX = portrait ? 310 : 530;

    let text = `${construction.workOrder} - ${construction.address}`;
    context.fillText(text, 5, 36);

    var location =
      Math.round(geolocation.latitude * 10000) / 10000 +
      ' ' +
      Math.round(geolocation.longitude * 10000) / 10000 +
      ' Accuracy: ' +
      Math.round(geolocation.accuracy) +
      'm';
    context.fillText(location || '', 5, bottomTextLocationY);

    var date = new Date();
    context.fillText(
      date.toLocaleDateString() + ' ' + date.toLocaleTimeString(),
      bottomTextLocationX,
      bottomTextLocationY
    );
  };

  const cancelImage = () => {
    setImage(null);
    setPreview(null);
    getVideo(); // which starts camera again
  };

  const stopCamera = useCallback(
    () => {
      stopMediaStream(videoRef.current);
    },
    [videoRef],
  );

  const done = useCallback(() => {
    stopCamera();
    setTimeout(() => {
    history.push('/field/construction/' + id + '/' + taskId);
  }, 100);
  }, [history, id, taskId, stopCamera]);

  const onModeClick = () => {
    setShowTitle(!showTitle);
    setUseMulti(!useMulti);
  };

  return (
    <>
      <Sync hidden />
      {!image && (
        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: "hidden"}}>
          <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <meta name="HandheldFriendly" content="true" />
          <script>
          {`
          document.addEventListener('gesturestart', function(e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    //document.body.style.zoom = 0.99;
});

document.addEventListener('gesturechange', function(e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    //document.body.style.zoom = 0.99;
});

document.addEventListener('gestureend', function(e) {
    e.preventDefault();
    // special hack to prevent zoom-to-tabs gesture in safari
    //document.body.style.zoom = 0.99;
});`}
          </script>

          </Helmet>
          <div
            className={`image-capture ${successFlag ? 'capture-success' : 'capture-normal'}`}
            style={{ width: '100vw', height: '100vh', position: 'absolute', top: 0, left: 0 }}
          >
            <video
              className="image-capture"
              playsInline
              id="imageCapture"
              src=""
              muted
              autoPlay
              ref={videoRef}
              style={{ position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh' }}
            />
          </div>
          <div style={{ position: 'absolute', left: 0, top: 0, width: '100vw', height: '100vh' }}>
            <div className="mt-3 text-center">
              {!isIOS &&
              <button
                id="toggleFlash"
                disabled={showTitle}
                className="mb-1 mr-1 btn btn-warning btn-lg"
                onClick={() => setFlash(!flash)}
              >
                {flash ? 'Flash:ON' : 'Flash:OFF'}
              </button>
              }
              <button disabled={showTitle} className="mb-1 mr-1 btn btn-secondary btn-lg" onClick={done}>
                Done
              </button>
              <button className="float-right mt-2 mb-1 mr-1 btn btn-secondary" onClick={() => onModeClick()}>
                {!showTitle && !useMulti && <span>&#9776;</span>}
                {showTitle && !filename && <span>&#9776;</span>}
                {(showTitle || useMulti) && filename && <span>&#9776;</span>}
              </button>
              <div className="float-left p-1 ml-1" style={{ clear: 'both', color: '#fff', backgroundColor: '#000' }}>
                {(showTitle || useMulti) && filename && (
                  <span>
                    {filename} #{imageNumber} - [{tags.join(', ').trim()}]
                  </span>
                )}
              </div>
            </div>
          </div>

          <div style={{ position: 'absolute', left: 0, bottom: 0, width: '100vw' }}>
            <div className="mb-3 text-center">
              <button
                id="capture"
                disabled={showTitle && readyFlag}
                className="btn btn-primary btn-lg"
                onClick={(e) => capture(e.target)}
              >
                Take {(!showTitle && !useMulti) && 'Single '}Picture
              </button>
            </div>
          </div>

          {showTitle && (
            <div
              className="card"
              style={{ margin: 'auto', position: 'absolute', left: 0, right: 0, top: 100, width: 'calc(100vw - 10%)' }}
            >
              <div className="card-body">
                <div className="card-title">
                  <h5>Multiple Images</h5>
                </div>
                <div className="p-1 pt-3" style={{ backgroundColor: '#fff' }}>
                  <div className="mb-2">
                    Take multiple images quickly by providing the title and starting number. Each time you click Take
                    Picture the image number will increment.
                  </div>
                  <div className="row">
                    <div className="col form-group">
                      <label htmlFor="filename" className="form-label required">
                        Title
                      </label>
                      <input
                        type="text"
                        id="filename"
                        maxLength={32}
                        className="form-control"
                        value={filename ?? ''}
                        placeholder="e.g. pit"
                        onChange={(e) => {
                          setFileName(e.target.value === '' ? null : e.target.value);
                          setImageNumber(1);
                        }}
                      />
                    </div>

                    <div className="col form-group">
                      <label htmlFor="imageCount" className="form-label">
                        Next Image #
                      </label>
                      <input
                        type="number"
                        id="imageCount"
                        className="form-control"
                        min={1}
                        value={imageNumber || 1}
                        placeholder="Next image number"
                        onChange={(e) => {
                          if (!isNaN(parseInt(e.target.value))) {
                            setImageNumber(parseInt(e.target.value ?? 1));
                          } else {
                            setImageNumber(1);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {comments === '' && storedComment && (
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => setComments(storedComment)}
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '100%',
                          display: 'block'
                        }}
                      >
                        {storedComment}
                      </button>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="comments" className="form-label">
                      Description/Comments
                    </label>
                    <textarea
                      rows="2"
                      id="comments"
                      className="form-control"
                      value={comments}
                      placeholder="Add any comments here"
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>

                  <div className="text-center mt-3">
                    <button
                      disabled={!valid}
                      className="btn btn-primary"
                      style={{ minWidth: '100px' }}
                      onClick={() => {
                        sessionStorage.setItem(
                          'last-image-comment',
                          JSON.stringify({ taskId: taskId, comments: comments })
                        );
                        if (filename) {
                          setUseMulti(true);
                        }
                        setShowTitle(false);
                      }}
                    >
                      Ok
                    </button>{' '}
                    {filename ? (
                      <button
                        className="btn btn-link"
                        style={{ minWidth: '100px' }}
                        onClick={() => {
                          setUseMulti(false);
                          setShowTitle(false);
                          setFileName(null);
                          setImageNumber(1);
                        }}
                      >
                        Clear
                      </button>
                    ) : (
                      <button className="btn btn-link" style={{ minWidth: '100px' }} onClick={done}>
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {preview && (
            <Modal size="md" isOpen={preview !== null && preview.image !== null} toggle={() => setPreview(null)}>
              <ModalHeader>Preview</ModalHeader>
              <ModalBody>
                <div>It appears there maybe an issue with this image</div>
                <div>
                  <img src={preview.image} crossOrigin="Anonymous" className="img-fluid" alt="..." />
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-secondary mr-3"
                  onClick={() => {
                    imageIsOk(null);
                  }}
                >
                  Photo is ok
                </button>
                <button className="btn btn-primary" onClick={() => cancelImage()}>
                  Try Again
                </button>
              </ModalFooter>
            </Modal>
          )}
          {captureError && (
            <Modal size="md" isOpen={captureError !== null} toggle={() => setCaptureError(null)}>
              <ModalBody>
                <div>Error while saving image:</div>
                <div>{captureError}</div>
              </ModalBody>
              <ModalFooter>
                <button className="btn btn-primary" onClick={() => setCaptureError(null)}>
                  Close
                </button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      )}

      <div className="mb-5 pt-3">
        <canvas style={{ display: 'none' }} ref={canvasRef} />

        {image && (
          <div className="text-center" style={{ display: image ? '' : 'none' }}>
            <div className="form-group">
              <label htmlFor="filename" className="form-label required">
                Title
              </label>
              <input
                type="text"
                id="filename"
                className="form-control"
                maxLength={32}
                value={filename ?? ''}
                placeholder="e.g. pit"
                onChange={(e) => setFileName(e.target.value === '' ? null : e.target.value)}
              />
            </div>

            {/* <div className="form-group">
                <label className="form-label required">Tags</label>
                <div>
                { statuses.map((status, index) => {
                    return <button key={index} className={'btn btn-sm mb-1 mr-1 ' + (tags.includes(status) ? 'btn-success' : 'btn-secondary')} onClick={() => toggleStatus(status)}>{status}</button>
                })}
                </div>
              </div> */}

            <div>
              {comments === '' && storedComment && (
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => setComments(storedComment)}
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    display: 'block'
                  }}
                >
                  {storedComment}
                </button>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="comments" className="form-label">
                Description/Comments
              </label>
              <textarea
                rows="5"
                id="comments"
                className="form-control"
                value={comments}
                placeholder="Add any comments here"
                onChange={(e) => setComments(e.target.value)}
              />
            </div>

            <div className="text-center mt-3 mb-3">
              <button
                disabled={!valid}
                className="btn btn-primary"
                onClick={() => {
                  saveImage(image, filename);
                  sessionStorage.setItem('last-image-comment', JSON.stringify({ taskId: taskId, comments: comments }));
                }}
              >
                Save
              </button>{' '}
              <button className="btn btn-link" onClick={() => cancelImage()}>
                Cancel
              </button>
            </div>
          </div>
        )}

        <img src={image} className="img-fluid mb-5" />
      </div>
    </>
  );
};

export default Capture;
