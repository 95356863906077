import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import * as serviceWorker from './serviceWorker';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useGlobal('showReload');
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    setShowReload(false);
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Modal isOpen={showReload}>
      <ModalHeader>Update available</ModalHeader>
      <ModalBody>
        <p>Great News! LeQuinn has been updated</p>
        <p>You just need to refresh to get the latest version</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => reloadPage()}>
          Refresh
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ServiceWorkerWrapper;
