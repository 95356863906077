import React, { useRef, useEffect, useState } from 'react';
import GraphiQL from 'graphiql';
import fetch from 'isomorphic-fetch';
import { useAuth } from '../../../auth/AuthProvider';

import 'graphiql/graphiql.css';

export const Graphiql = (props) => {
  const headers = useRef({ 'Content-Type': 'application/json' });
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  const graphQLFetcher = (graphQLParams, headers) => {
    return fetch(window.location.origin + '/graphql', {
      method: 'post',
      headers: { ...headers },
      body: JSON.stringify(graphQLParams)
    }).then((response) => {
      return response.json();
    });
  };

  useEffect(() => {
    if (auth.token) {
      headers.current.authorization = `Bearer ${auth.token}`;
      setLoading(false);
    }
  }, []);

  return !loading ? (
    <div style={{ display: 'flex', height: '100vh' }}>
      <GraphiQL fetcher={(params) => graphQLFetcher(params, headers.current)} />
    </div>
  ) : (
    <p>Loading</p>
  );
};
